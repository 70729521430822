import React from 'react';
import Hero4 from '../images/hero4.svg'
import Hero3 from '../images/hero3.svg'


export const AboutUs = () => {
  return (
    <div id="about">
      <div className="container">
        <div className="row ">
          <div className="col-xs-12 col-md-4">
            {/* {" "} */}
            {/* <img src="img/about.jpg" className="img-responsive" alt="" />{" "} */}
            <div className="">
                            <img src={Hero4} alt="" />
                            {/* <div className="hero_content">
                                <p className="hero_top">
                                    Let's Discuss
                                </p>
                                <h1 className="hero_heading">IVR Number</h1>
                                <p className="cont">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsum nulla animi aliquid illum fuga, aut doloremque nisi unde est iste.</p>
                            </div> */}
                        </div>
                        {/* <div className="">
                            <img src={Hero3} alt="" />
                            
                        </div> */}

          </div>
          <div className="col-xs-12 col-md-8">
            <div className="about-text">
              <h2>About Us</h2>
              <p>NSS Soft is a leading software development company dedicated to delivering high-quality solutions to clients worldwide. With a focus on innovation and excellence, we specialize in developing custom software, mobile applications,
                 and web solutions tailored to meet the unique needs of each client.</p>

                 <p>
                  Our team of skilled professionals is committed to providing cutting-edge technology solutions that 
                  drive business growth and success. We strive to maintain the highest standards of quality 
                  and customer satisfaction in every project we undertake.</p>
                  <p>
                  At NSS Soft, we believe in forging long-term partnerships with our clients,
                   working closely with them to understand their goals and objectives.
                    Whether you're a startup looking to launch a new product or an established enterprise
                     seeking to optimize your processes, we have the expertise and experience to help you achieve your
                      vision.</p>

                      <p>
                      In addition to our commitment to quality and support, NSS Soft is known for our fast development turnaround times. We understand the importance of delivering solutions quickly in today's fast-paced business environment, and our agile development approach allows us to rapidly prototype, iterate, and deploy software solutions without compromising on quality.
                      </p>
             
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default AboutUs;
