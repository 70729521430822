


const Slider = ({ showSide, setShowSide }) => {
    function handleCloseSlide() {
        setShowSide((prev) => !prev)
    }
    return (
       
<div className={'side_bar ' + (showSide && 'active')}>
    <div className="close" onClick={handleCloseSlide}><i class="fa-solid fa-xmark"></i></div>
    <ul className="mobile_links" onClick={handleCloseSlide}>
        <li>
            <a href="#about">What is IVR?</a>
        </li>
        <li>
            <a href="#features">IVR Featues</a>
        </li>
        <li>
            <a href="#benefit">IVR Benefits</a>
        </li>
        <li>
            <a href="#plan">Plan And Pricing</a>
        </li>
        <li>
            <a href="#contact">Contact</a>
        </li>

        <li className="mob_log">
            <a href="https://nsssoft.online">LogIn</a>
        </li>
    </ul>
</div>
  )
}

export default Slider