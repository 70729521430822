import React from "react";
import "./style.css";
import Logo from "../images/ivrlogo.gif";
import Whatsapp from "../images/whatsapp.png";
import Phone from "../images/telephone.png";

const url = "https://api.whatsapp.com/send?phone=918299603247&text=Hi, I'm interested for IVR number";
const Header = ({ showSide, setShowSide }) => {

  function openSlider() {
    setShowSide((prev) => !prev);
  }

  return (
    <header className="header ">
      <div className="whatsapp">
        <a target="_blank" href={url} rel="noreferrer">
          <img src={Whatsapp} alt="" />
        </a>
      </div>
      <div className="phone">
        <a href="tel: +918299603247">
          <img src={Phone} alt="" />
        </a>
      </div>

      {/* <div className="logo">
        <span href="#hero">  <img src={Logo} alt="" /></span>

      </div> */}
      {/* <div className="header_left"> */}
      <div className="text-center">
        <ul className="nav_links">
          <li className="mobile_bar" onClick={openSlider}>
            <span></span>
            <span></span>
            <span></span>
          </li>
          <li>
            <a href="#about">About Us</a>
        </li>
        <li>
            <a href="#features">Product & Solutions</a>
        </li>
        {/* <li>
            <a href="#features">IVR Featues</a>
        </li>
        <li>
            <a href="#benefit">IVR Benefits</a>
        </li> */}
        <li>
            <a href="#plan">Terms & Conditions</a>
        </li>
        <li>
            <a href="#contact">ContactUs</a>
        </li>

        <li className="mob_log floats-end">
            <a href="https://nsssoft.online">IVR-LogIn</a>
        </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
