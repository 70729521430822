import React from 'react'



const Features = ({ref}) => {




  return (
    <div id='features' ref={ref}>
      <h1 className="features_heading">Our Services</h1>
     

      <div className="row">
          
          <div className="col-md-4">
                <div className='card'>
                  <div className='card-header text-center'>
                    <h2>OBD</h2>
                  </div>
                  <div className='card-body'>
                    <div className='cardbodytext'>At OBD Outbound IVR, we offer advanced outbound dialing (OBD) and 
                      interactive voice response (IVR) solutions. Our services streamline communication, 
                      allowing businesses to reach customers effectively with personalized messages and 
                      automate incoming call management. With a focus on reliability and innovation, 
                      we tailor our solutions to meet each client's unique needs. Partner with us to
                       enhance customer engagement and optimize operational efficiency through our cutting-edge
                        OBD and IVR technology. </div>
                  </div>
                </div>
          </div>
          <div className="col-md-4">
          <div className='card'>
                  <div className='card-header text-center'>
                    
                    <h2>IVR</h2>
                  </div>
                  <div className='card-body'>
                    <div className='cardbodytext'>Our IVR (Interactive Voice Response) solutions empower businesses with 
                      automated call handling, efficient routing, and customer self-service options. 
                      Streamlining inbound communication, we enhance customer experience and operational efficiency.
                       With personalized greetings and seamless navigation, our IVR systems optimize interactions,
                        reducing wait times and boosting productivity. Tailored to your needs, our solutions ensure reliable and scalable performance, driving satisfaction and loyalty. Upgrade your customer service with our advanced IVR technology today. </div>
                  </div>
                </div>
          </div>
          <div className="col-md-4">
          <div className='card'>
                  <div className='card-header text-center'>
                   
                    <h2>Call Center Dialer</h2>
                  </div>
                  <div className='card-body'>
                    <div className='cardbodytext'>Our Call Center Dialer Software revolutionizes outbound communication by 
                      automating dialing processes, increasing agent productivity, and optimizing call routing.
                       With predictive, preview, or progressive dialing modes, we ensure maximum efficiency in reaching your audience.
                       Features include call recording, real-time analytics, and CRM integration, empowering agents 
                       with valuable insights and enhancing customer interactions. Scalable and customizable, our 
                       software adapts to your business needs, delivering seamless communication solutions. Experience heightened 
                       performance and customer satisfaction
                       with our innovative call center dialer software. </div>
                  </div>
                </div>
          </div>
          <div className="col-md-4">
          <div className='card'>
                  <div className='card-header text-center'>
                    

                    <h2>Missed Call Solutions</h2>
                  </div>
                  <div className='card-body'>
                    <div className='cardbodytext'>Missed Call Solutions offer a convenient and efficient way for 
                    businesses to engage with customers. By allowing customers to initiate contact without
                     incurring charges, our solutions increase accessibility and encourage interaction.
                      With instant notifications and customizable responses, businesses can promptly follow up on
                       missed calls, ensuring no opportunity is lost. Our platform integrates seamlessly with CRM 
                       systems, enabling streamlined lead management and personalized communication.
                        Experience enhanced customer engagement and increased efficiency with our comprehensive 
                        Missed Call Solutions. </div>
                  </div>
                </div>
          </div>
          <div className="col-md-4">
          <div className='card'>
                  <div className='card-header text-center'>
                  <h2>API Baesd  Solutions</h2>
                  </div>
                  <div className='card-body'>
                    <div className='cardbodytext'>API-Based Solutions provide businesses with flexible and
                     scalable tools to integrate and streamline processes. With robust APIs, our solutions 
                     enable seamless communication between different software systems, empowering businesses to
                      automate workflows, enhance efficiency, and deliver innovative services. Whether it's integrating
                       payment gateways, connecting with third-party applications, or enabling data exchange, our API-based 
                       solutions offer versatility and customization. Experience the power of APIs to transform
                     your business operations and drive growth with our comprehensive API-Based Solutions. </div>
                  </div>
                </div>
          </div>
          <div className="col-md-4">
          <div className='card'>
                  <div className='card-header text-center'>
                  <h2>Custom Solutions</h2>
                  </div>
                  <div className='card-body'>
                    <div className='cardbodytext'>Custom Solutions offer tailored answers to unique business needs,
                     addressing specific challenges and maximizing efficiency. Our team collaborates closely with clients to
                     understand their requirements and design bespoke solutions that align with their goals.
                      From software development to system integration, we provide comprehensive services to meet
                       diverse needs. With a focus on innovation and scalability, our Custom Solutions deliver tangible
                        results and empower businesses to thrive in competitive markets. Partner with us to unlock the full potential
                     of your business with custom-built solutions designed for success. </div>
                  </div>
                </div>
          </div>
         
</div>
    </div>
  )
}

export default Features