import React, { useEffect, useState } from "react";

const Contact = () => {
  const initialValue = { number: "" };
  const [formValue, setFormValue] = useState(initialValue);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValue));
    setIsSubmit(true);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };
  useEffect(() => {

  }, [formErrors]);
  const validate = (values) => {
    const errors = {};
    if (values.number.length < 10) {
      errors.number = "Should be equal to 10 digits";
    } else if (values.number.length > 10) {
      errors.number = "Should be equal to 10 digits";
    }
    else {
      errors.number = "";
    }

    return errors;
  };

  return (
    <div id="contact">



      <h1 className="contact_heading">CONTACT</h1>
      <div className="foot_container">
        <div className="address">
          <p className="foot_phone">
            <a href="tel: +91 7982755571">
              <i class="fa-solid fa-phone"></i> +918299603247
            </a>
          </p>
          <p className="foot_phone">
            <a href="mailto: info@ivrnumber.com">
              <i class="fa-solid fa-envelope"></i> admin@nsssoft.in
            </a>
          </p>
          <p className="foot_phone ffs">
            <i class="fa-solid fa-building"></i>
            Address: Wave Galleria Wave City, KingWood Enclave, Ghaziabad, Uttar Pradesh 201015
          </p>
        </div>

        <div className="form_container">
          {Object.keys(formErrors).length === 0 && isSubmit ? (
            <div className="ui message success">Signed in successfully</div>
          ) : (
            <p></p>
          )}
          <form onSubmit={handleSubmit}>
            <input type="text" placeholder="Name" />
            <input type="email" placeholder="example@gmail.com" />
            <input
              type="tel"
              name="number"
              placeholder="Mobile No."
              onChange={handleChange}
              value={formValue.number}
            />
            <p className="error_mssg" >{formErrors.number}</p>

            <button className="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
